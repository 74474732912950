document.addEventListener("turbolinks:load", function() {
    if(window.location.pathname == "/weeklypicks_categories") {
        let method;
        let requiredFields = [];
        let weeklypicks_category_id;
        let table_weeklypicks_categories;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_weeklypicks_categories != null)
                table_weeklypicks_categories.destroy();
        })

        table_weeklypicks_categories = $('#table-weeklypicks_categories').DataTable({
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bOrder": true,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        var modalCategory = document.getElementById('modal-weeklypicks_categories')
        modalCategory.addEventListener('show.bs.modal', function (e) {
            method = $(e.relatedTarget).data('method')
        
            if (method == "new") {
            }
            else if (method == "edit") {
                weeklypicks_category_id = $(e.relatedTarget).data("weeklypicks_category_id")
                get()
            }
        })

        modalCategory.addEventListener('hidden.bs.modal', function (e) {
            removeInvalidField()
            resetFields()
        });

        $("#btn-save-weeklypicks-category").on("click", function() {
            if (!$("#weeklypicks_category-name").val()) requiredFields.push("#weeklypicks_category-name")
            if (method == "new") {
                if (!$("#weeklypicks_category-image").val()) requiredFields.push("#weeklypicks_category-image")
            }

            if (requiredFields.length > 0) {
                requiredFields.forEach(function(field, i,) {
                    $(field).addClass("is-invalid")
                });

                requiredFields = []
                
                return false
            }

            if (method == "new") {
                create()
            }
            else {
                update()
            }
        })
        
        /***** FUNCTIONS *****/

        function get() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            let url = "/weeklypicks_categories/get"

            if (weeklypicks_category_id != null) url += `/${weeklypicks_category_id}`

            $.ajax({
                url: url,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    if (weeklypicks_category_id != null) {

                        $("#weeklypicks_category-name").val(data.name)
                        $("#weeklypicks_category-position").val(data.position)
                        $("#weeklypicks_category-thumbnail").attr("src", data.image.url)
                        $("#weeklypicks_category-thumbnail").removeClass("visually-hidden")
                    }
                    else {
                        jQuery.each(data, function (i, val) {
                            let newRow = table_weeklypicks_categories.row.add( [
                                i+1,
                                val.name,
                                `<img src='${val.image.url}' class='photo' alt='image' width='200'>`,
                                val.position,
                                `<a href='javascript:void(0)' title='Edit' type='button' data-weeklypicks_category_id='${val.id}' data-toggle="modal" data-target="#modal-weeklypicks_categories" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a> ` +
                                `<a href='javascript:void(0)' class='delete_weeklypicks_category btn_delete_weeklypicks_category_${val.id}' data-weeklypicks_category_id=${val.id}><i class='icon-delete fas fa-trash'></i></a>`
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "weeklypicks_category-" + val.id);
        
                            $('.btn_delete_weeklypicks_category_' + val.id).on("click", function(e){
                                destroy(val.id, val.name)
                            });
                        });
                    }
                
                    JsLoadingOverlay.hide();
                }
            })
        }
    
        function create() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });
            
            var formData = new FormData();
            formData.append('name', $("#weeklypicks_category-name").val().trim());
            formData.append('image', $("#weeklypicks_category-image")[0].files[0]);
            formData.append('position', $("#weeklypicks_category-position").val());

            $.ajax({
                url: `/weeklypicks_categories`,
                type: 'POST',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    let newRow = table_weeklypicks_categories.row.add( [
                        table_weeklypicks_categories.rows().count()+1,
                        data.name,
                        `<img src='${data.image.url}' class='photo' alt='image' width='200'>`, 
                        data.position,
                        `<a href='javascript:void(0)' title='Edit' type='button' data-weeklypicks_category_id='${data.id}' data-toggle="modal" data-target="#modal-weeklypicks_categories" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a> ` +
                        `<a href='javascript:void(0)' class='delete_weeklypicks_category btn_delete_weeklypicks_category_${data.id}' data-weeklypicks_category_id=${data.id}><i class='icon-delete fas fa-trash'></i></a>`
                    ] ).draw( false ).node();

                    $(newRow).attr("id", "weeklypicks_category-" + data.id);

                    $('.btn_delete_weeklypicks_category_' + data.id).on("click", function(e){
                        destroy(data.id, data.name)
                    });

                    $("#modal-weeklypicks_categories .close").click();

                    JsLoadingOverlay.hide();
                }
            });
        }
        
        function update() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });
            
            var formData = new FormData();
            formData.append('name', $("#weeklypicks_category-name").val().trim());
            if ($("#weeklypicks_category-image")[0].files[0] != undefined) formData.append('image', $("#weeklypicks_category-image")[0].files[0]);
            formData.append('position', $("#weeklypicks_category-position").val());

            $.ajax({
                url: `/weeklypicks_categories/${weeklypicks_category_id}`,
                type: 'PUT',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    photo = `<img src='${data.image.url}' class='photo' width='200px' alt='image'>`
                    $("#weeklypicks_category-" + weeklypicks_category_id + " td:nth-child(2)").html(data.name)
                    $("#weeklypicks_category-" + weeklypicks_category_id + " td:nth-child(3)").html(photo)
                    $("#weeklypicks_category-" + weeklypicks_category_id + " td:nth-child(4)").html(data.position)

                    $("#modal-weeklypicks_categories .close").click();
                    
                    JsLoadingOverlay.hide();
                }
            });
        }

        function destroy(id, name) {
            Swal.fire({
                title: "Delete this category?",
                text: `${name}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
              }).then((result) => {
                if (result.isConfirmed) {
                    JsLoadingOverlay.show({
                        'spinnerIcon': 'ball-pulse-sync'
                    });

                    $.ajax({
                        url: `/weeklypicks_categories/${id}`,
                        type: 'DELETE',
                        dataType: "json",
                        success: function (data) {
                            table_weeklypicks_categories.row($("#weeklypicks_category-" + id)).remove().draw();

                            JsLoadingOverlay.hide();
                        }
                    });
                }
            })
        }

        function resetFields() {
            $("#weeklypicks_category-name").val("")
            $("#weeklypicks_category-image").val("")
            $("#weeklypicks_category-position").val("")
            $("#weeklypicks_category-thumbnail").attr("src", "")
            $("#weeklypicks_category-thumbnail").addClass("visually-hidden")
         
            weeklypicks_category_id = null
        }

        function removeInvalidField() {
            $("#weeklypicks_category-name").removeClass("is-invalid")
            $("#weeklypicks_category-image").removeClass("is-invalid")
        }

        get()
    } 
})