document.addEventListener("turbolinks:load", function() {
    if(window.location.pathname == "/cities") {
        let city_id;
        let table_cities;
        let requiredFields = [];

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_cities != null)
            table_cities.destroy();
        })

        table_cities = $('#table-cities').DataTable({
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bOrder": true,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            "searching": false,
            'order' : [[0,'asc']]
        });

        var modalCity = document.getElementById('modal-city')
        modalCity.addEventListener('show.bs.modal', function (e) {
            method = $(e.relatedTarget).data('method')

            city_id = $(e.relatedTarget).data("city_id")

            get()
        })

        modalCity.addEventListener('hidden.bs.modal', function (e) {
            resetFields()
        });

        $("#btn-save-city").on("click", function() {
            if (!$("#city-photo").val()) requiredFields.push("#city-photo")
            
            if (requiredFields.length > 0) {
                requiredFields.forEach(function(field, i,) {
                    $(field).addClass("is-invalid")
                });

                requiredFields = []
                
                return false
            }

            update()
        })

        /***** FUNCTIONS *****/
        function get() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            let url = "/cities/get"

            if (city_id != null) url += `/${city_id}`

            $.ajax({
                url: url,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    if (city_id != null) {
                        $("#city-name").val(data.name)
                        var photo = data.photo.url != null ? data.photo.url : '/assets/default_menu.png'
                        $("#city-thumbnail").attr("src", photo)
                        $("#city-thumbnail").removeClass("visually-hidden")
                    }
                    else {
                        jQuery.each(data, function (i, val) {
                            let photo = val.photo.url != null ? `<img src='${val.photo.url}' alt='photo' width='100'>` : "<img src='/assets/default_menu.png' width='100' alt='photo'>"
                            let newRow = table_cities.row.add( [
                                val.name,
                                photo,
                                `<a href='javascript:void(0)' title='Edit' type='button' data-city_id='${val.id}' data-toggle="modal" data-target="#modal-city" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a> `
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "city-" + val.id);
        
                            $('.btn_delete_city_' + val.id).on("click", function(e){
                                destroy(val.id, val.name)
                            });
                        });
                    }
                
                    JsLoadingOverlay.hide();
                }
            })
        }

        function update() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });
            
            var formData = new FormData();
            if ($("#city-photo")[0].files[0] != undefined) formData.append('photo', $("#city-photo")[0].files[0]);
            
            $.ajax({
                url: `/cities/${city_id}`,
                type: 'PUT',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    photo = `<img src='${data.photo.url}' class='photo' width='100px' alt='image'>`
                    $("#city-" + city_id + " td:nth-child(2)").html(photo)

                    $("#modal-city .close").click();
                    
                    JsLoadingOverlay.hide();
                }
            });
        }

        get()
    }
});