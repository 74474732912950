document.addEventListener("turbolinks:load", function() {
    if(window.location.pathname == "/weeklypicks") {
        let method;
        let requiredFields = [];
        let weeklypicks_id;
        let table_weeklypicks;
        let post_slug;
        let mcc_id;
        let content;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_weeklypicks != null)
                table_weeklypicks.destroy();
        })

        table_weeklypicks = $('#table-weeklypicks').DataTable({
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bOrder": true,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        $("#table-weeklypicks_wrapper > .row:nth-child(1) > div:nth-child(1)").append("<div style='margin-left:20px'>" +
            "<div style='display:inline; margin-right:10px'>Category:</div>"  +
            "<div class='input-group' style='width:300px !important; display: inline-flex;'>" + 
                "<select class='form-select' id='categories' style='font-weight:bold' >" + 
                "</select>" +
            "</div>" +
        "</span>")

        var modalWeeklypicks = document.getElementById('modal-weeklypicks')
        modalWeeklypicks.addEventListener('shown.bs.modal', function (e) {
            $("#weeklypicks-post_slug").focus()
        });

        modalWeeklypicks.addEventListener('show.bs.modal', function (e) {
            method = $(e.relatedTarget).data('method')
          
            $("#weeklypicks-post_slug").on("focus")
            $("#weeklypicks-categories").val($("#categories").val())

            if (method == "new") {
                $("#weeklypicks-post_slug").attr("disabled", false)
            }
            else if (method == "edit") {
                $("#weeklypicks-post_slug").attr("disabled", true)

                slug = $(e.relatedTarget).data("slug")
                weeklypicks_id = $(e.relatedTarget).data("weeklypicks_id")

                find_post(slug)
            }
        })

        modalWeeklypicks.addEventListener('hidden.bs.modal', function (e) {
            resetFields()
        });

        $("#weeklypicks-post_slug").on("blur", function() {
            slug = $(this).val()
            find_post(slug)
        })

        $("#categories").on("change", function() {
            get($(this).val())
        })

        $("#btn-save-weeklypicks").on("click", function(){
            if (requiredFields.length > 0) {
                requiredFields.forEach(function(field, i,) {
                    $(field).addClass("is-invalid")
                });

                requiredFields = []
                
                return false
            }
            
            if (method == "new") {
                create()
            }
            else {
                update()
            }
        })

        /**** FUNCTIONS ****/

        function get(weeklypicks_category_id) {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            let url = "/weeklypicks/get"
            if (weeklypicks_category_id != null) url = "/weeklypicks/get/" + weeklypicks_category_id

            $.ajax({
                url: url,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    table_weeklypicks.rows().clear().draw();
                    if (weeklypicks_category_id == null) {
                        jQuery.each(data.categories, function (i, val) {
                            $("#categories").append(`<option value='${val.id}'>${val.name}</option>`)
                            $("#weeklypicks-categories").append(`<option value='${val.id}'>${val.name}</option>`)
                        });
                    }
                    else {
                        
                    }

                    jQuery.each(data.posts, function (i, val) {
                        let newRow = table_weeklypicks.row.add( [
                            val.title,
                            `<img src='${val.image}' class='photo' alt='image' width='200'>`, 
                            `<a href='javascript:void(0)' title='Edit' type='button' data-weeklypicks_id='${val.id}' data-slug='${val.slug}' data-toggle="modal" data-target="#modal-weeklypicks" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a> ` +
                            `<a href='javascript:void(0)' class='delete_weeklypicks btn_delete_weeklypicks_${val.id}' data-weeklypicks_id=${val.id}><i class='icon-delete fas fa-trash'></i></a>`
                        ] ).draw( false ).node();
    
                        $(newRow).attr("id", "weeklypicks-" + val.id);
    
                        $('.btn_delete_weeklypicks_' + val.id).on("click", function(e){
                            destroy(val.id, val.title)
                        });
                    });

                    JsLoadingOverlay.hide();
                }
            });
            
        }

        function find_post(slug) {

            if (method == "edit") {
                $("#weeklypicks-post_slug").val(slug)
            }
            if(slug != "" && post_slug != slug) {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-pulse-sync'
                });

                post_slug = slug

                $.ajax({
                    url: '/weeklypicks/get_post_by_slug/' + slug,
                    type: 'GET',
                    dataType: "json",
                    success: function (data) {
                        if (data.length > 0) {
                            $("#weeklypicks-post_title").text(data[0]['title']['rendered'])
                            $("#weeklypicks-post_image").attr("src", data[0]['jetpack_featured_media_url'])
                            $("#weeklypicks-post_image").removeClass("visually-hidden")
                            $(".alert-weeklypicks").addClass("visually-hidden")

                            $("#btn-save-weeklypicks").attr("disabled", false)
                            mcc_id = data[0]['id']
                            content = data[0]['content']['rendered']
                        }
                        else {
                            $("#weeklypicks-post_image").addClass("visually-hidden")
                            $("#weeklypicks-post_title").text("")
                            $("#weeklypicks-post_image").attr("src", "")
                            $(".alert-weeklypicks").removeClass("visually-hidden")

                            $("#btn-save-weeklypicks").attr("disabled", true)
                        }

                        JsLoadingOverlay.hide();
                    }
                });
            }
            else if(slug == "") {
                $("#weeklypicks-post_image").addClass("visually-hidden")
                $("#weeklypicks-post_title").text("")
                $("#weeklypicks-post_image").attr("src", "")
                $(".alert-weeklypicks").addClass("visually-hidden")
                post_slug = ""
                $("#btn-save-weeklypicks").attr("disabled", true)
            }
        }

        function create() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            var formData = new FormData();
            formData.append('mcc_id', mcc_id);
            formData.append('title', $("#weeklypicks-post_title").text());
            formData.append('slug', $("#weeklypicks-post_slug").val().trim());
            formData.append('image', $("#weeklypicks-post_image").attr("src"));
            formData.append('weeklypicks_category_id', $("#weeklypicks-categories").val());
            formData.append('content', content);
            
            $.ajax({
                url: `/weeklypicks`,
                type: 'POST',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    let newRow = table_weeklypicks.row.add( [
                        data.title,
                        `<img src='${data.image}' class='photo' alt='image' width='200'>`, 
                        `<a href='javascript:void(0)' title='Edit' type='button' data-weeklypicks_id='${data.id}' data-slug='${data.slug}' data-toggle="modal" data-target="#modal-weeklypicks" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a> ` +
                        `<a href='javascript:void(0)' class='delete_weeklypicks btn_delete_weeklypicks_${data.id}' data-weeklypicks_id=${data.id}><i class='icon-delete fas fa-trash'></i></a>`
                    ] ).draw( false ).node();

                    $(newRow).attr("id", "weeklypicks-" + data.id);

                    $('.btn_delete_weeklypicks_' + data.id).on("click", function(e){
                        destroy(data.id, data.name)
                    });

                    $("#modal-weeklypicks .close").click();

                    JsLoadingOverlay.hide();
                }
            });
        }

        function update() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            var formData = new FormData();
                formData.append('mcc_id', mcc_id);
                formData.append('weeklypicks_category_id', $("#weeklypicks-categories").val());
                formData.append('content', content);
                
            $.ajax({
                url: `/weeklypicks/${weeklypicks_id}`,
                type: 'PUT',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {

                    if ($("#weeklypicks-categories").val() != $("#categories").val()) {
                        table_weeklypicks.row($("#weeklypicks-" + weeklypicks_id)).remove().draw();
                    }
                    
                    $("#modal-weeklypicks .close").click();
                    
                    JsLoadingOverlay.hide();
                }
            })
        }
        
        function destroy(id, title) {
            Swal.fire({
                title: "Remove this post?",
                text: `${title}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
              }).then((result) => {
                if (result.isConfirmed) {
                    JsLoadingOverlay.show({
                        'spinnerIcon': 'ball-pulse-sync'
                    });

                    $.ajax({
                        url: `/weeklypicks/${id}`,
                        type: 'DELETE',
                        dataType: "json",
                        success: function (data) {
                            table_weeklypicks.row($("#weeklypicks-" + id)).remove().draw();

                            JsLoadingOverlay.hide();
                        }
                    });
                }
            })
        }

        function resetFields() {
            $("#weeklypicks-post_slug").val("")
            $("#weeklypicks-po st_slug").val("")
            $("#weeklypicks-post_title").text("")
            $("#weeklypicks-post_image").attr("src", "")
            $("#weeklypicks-post_image").addClass("visually-hidden")
            $(".alert-weeklypicks").addClass("visually-hidden")

            post_slug = ""
            weeklypicks_id = null
            mcc_id = null
            content = null
        }

        
        get()
    }
});