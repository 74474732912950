document.addEventListener("turbolinks:load", function() {
    if(window.location.pathname == "/categories") {
        let category_id;
        let table_categories;
        let requiredFields = [];

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_categories != null)
            table_categories.destroy();
        })

        table_categories = $('#table-categories').DataTable({
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bOrder": true,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            "searching": false,
            'order' : [[0,'asc']]
        });

        var modalCategory = document.getElementById('modal-category')
        modalCategory.addEventListener('show.bs.modal', function (e) {
            method = $(e.relatedTarget).data('method')

            category_id = $(e.relatedTarget).data("category_id")

            get()
        })

        modalCategory.addEventListener('hidden.bs.modal', function (e) {
            resetFields()
        });

        $("#btn-save-category").on("click", function() {
            if (!$("#category-photo").val()) requiredFields.push("#category-photo")
            
            if (requiredFields.length > 0) {
                requiredFields.forEach(function(field, i,) {
                    $(field).addClass("is-invalid")
                });

                requiredFields = []
                
                return false
            }

            update()
        })

        /***** FUNCTIONS *****/
        function get() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            let url = "/categories/get"

            if (category_id != null) url += `/${category_id}`

            $.ajax({
                url: url,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    if (category_id != null) {
                        $("#category-name").val(data.name)
                        $(".modal-category-title").text(data.name)
                        var photo = data.photo.url != null ? data.photo.url : '/assets/default_menu.png'
                        $("#category-thumbnail").attr("src", photo)
                        $("#category-thumbnail").removeClass("visually-hidden")
                    }
                    else {
                        jQuery.each(data, function (i, val) {
                            let photo = val.photo.url != null ? `<img src='${val.photo.url}' alt='photo' width='100'>` : "<img src='/assets/default_menu.png' width='100' alt='photo'>"
                            let newRow = table_categories.row.add( [
                                val.name,
                                photo,
                                `<a href='javascript:void(0)' title='Edit' type='button' data-category_id='${val.id}' data-toggle="modal" data-target="#modal-category" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a> `
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "category-" + val.id);
        
                            $('.btn_delete_category_' + val.id).on("click", function(e){
                                destroy(val.id, val.name)
                            });
                        });
                    }
                
                    JsLoadingOverlay.hide();
                }
            })
        }

        function update() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });
            
            var formData = new FormData();
            if ($("#category-photo")[0].files[0] != undefined) formData.append('photo', $("#category-photo")[0].files[0]);
            
            $.ajax({
                url: `/categories/${category_id}`,
                type: 'PUT',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    photo = `<img src='${data.photo.url}' class='photo' width='100px' alt='image'>`
                    $("#category-" + category_id + " td:nth-child(2)").html(photo)

                    $("#modal-category .close").click();
                    
                    JsLoadingOverlay.hide();
                }
            });
        }

        get()

        function resetFields() {
            $("#category-photo").val("")
        }
    }
});