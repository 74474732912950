// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "datatables.net-bs4"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Global
require("jquery");
require("tempusdominus-bootstrap-4")
require("flatpickr")
require("packs/global/bootstrap.min");
require("packs/global/nouislider.min");
require("packs/global/jarallax.min");
require("packs/global/on-screen.umd.min");
require("packs/global/smooth-scroll.polyfills.min");
require("packs/global/smooth-scroll.min");
require("packs/global/countUp.umd");
require("packs/global/notyf.min");
require("packs/global/datepicker.min");
require("packs/global/simplebar.min");
require("packs/global/jquery.timeago");
require("packs/global/volt");
require("sweetalert2")
require("jquery-toast-plugin")
require("jquery-autocomplete")
require("js-loading-overlay")

// App
require("packs/app/weeklypicks_categories")
require("packs/app/weeklypicks")
require("packs/app/services")
require("packs/app/members")
require("packs/app/admin_users")
require("packs/app/home_menus")
require("packs/app/categories")
require("packs/app/cities")

global.toastr = require("toastr")

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()