document.addEventListener("turbolinks:load", function() {
    var table_services;
    var service_id = null;
    var id_serv = null;
    var service_name_id = null;
    var method;

    document.addEventListener("turbolinks:before-cache", function() {
        if (table_services != undefined)
            table_services.destroy();
    });

    if (document.getElementById('modal-service')){
        var modalService = document.getElementById('modal-service')
        modalService.addEventListener('show.bs.modal', function (e) {
            service_id = $(e.relatedTarget).data("id_service")
            service_name_id = $(e.relatedTarget).data("id_service")

            method = $(e.relatedTarget).data('method')

            Get()
        })

        var modalServiceDetail = document.getElementById('modal-new_service')
        modalServiceDetail.addEventListener('shown.bs.modal', function (e) {
            $("#service-new_name").focus();

            if ($(e.relatedTarget).data('method') != "new")
                $("#service-new_name").val($("#services option:selected").text())

            if ($(e.relatedTarget).data('method') == "edit") {
                id_serv = $(e.relatedTarget).data("id_service")
            
                $.ajax({
                    url: `/services/get/${id_serv}`,
                    type: 'GET',
                    dataType: "json",
                    success: function (data) {
                        $("#service-thumbnail").removeClass("visually-hidden")
                        if (data.photo.url != null)
                            $("#service-thumbnail").attr('src', `${data.photo.url}`)
                        else 
                            $("#service-thumbnail").attr('src', '/assets/default_menu.png')
                    }
                })
            }
        })

        modalService.addEventListener('hidden.bs.modal', function (e) {
            Reset()
        });
    }

    function Init() {

        GetAllServices()

        table_services = $('#table-services').DataTable({
            "columnDefs": [
                {"className": "comment-fluid", "targets": 1}
            ],
            "pageLength": 100,
            "bPaginate": true,
            "bFilter": true,
            "bInfo": false,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            buttons: [
                'csv', 'excel', 'pdf'
            ]
        });

        $("#table-services_length").append("<span>" +
            "<select class='col-sm-3 form-select' id='services' aria-label='' style='margin-left: 30px; width:300px'>" + 
                "<option value=''>All services</option>" + 
            "</select>" +           
        "</span> </i>")

        $("#table-services .dataTables_empty").html("<img src='/assets/loader_2.svg' width='64'>")

        $("#btn-save-new_service").on("click", function(){
            if ($("#service-new_name").val() == "") {
                $("#service-new_name").addClass("is-invalid")
                return;
            }
            
            if (id_serv == null)
                CreateService()
            else
                UpdateService()
        })

        $("#btn-save-service").on("click", function(){
            if ($("#service-detail_name").val() == "") {
                $("#service-detail_name").addClass("is-invalid")
                return;
            }

            if (method == "new")
                CreateServiceName()
            else (method == "edit")
                UpdateServiceName()
        });

        $("#services").on("change", function(){
            service_id = $(this).val()

            if ($(this).val() != ""){
                $(this).next().remove()
                $(this).parent().append(` <div style='display:inline-block'> <a href='javascript:void(0)' type='button' data-id_service='${service_id}' data-toggle="modal" data-target="#modal-new_service" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a>
                <a href='javascript:void(0)' class='btn_delete_all_service_${service_id}' data-id_service='${service_id}'><i class='icon-delete fas fa-trash-alt'></i></a
                </div>`)

                $(`.btn_delete_all_service_${service_id}`).on("click", function(){
                    Swal.fire({
                        title: "Would you really want to delete this service ?",
                        icon: "warning",
                        text: ``,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Yes',
                    }).then(result => {
                        if (result.isConfirmed) {
                            DeleteService(service_id)
                        }
                    });
                })
            }
            else {
                $(this).next().remove()
            }

            Get()
        })
        
        $("#booking-service").on("change", function(){
            service_id = $(this).val()
            $('#booking-service_name').autocomplete('clear');
            $("#booking-service_name").val("")

            if ($("#booking-first_customer_note").attr("disabled") != "disabled") {
                var placeholder_first_reminder = "Your booking for '<service>' has been confirmed for '<date>'"
                placeholder_first_reminder = placeholder_first_reminder.replace('<service>', $("[id='booking-service'] :selected").text() + " " + $("#booking-service_name").val())
                placeholder_first_reminder = placeholder_first_reminder.replace('<date>', moment($("#booking-date").val(), 'YYYY-MM-DD HH:mm').utc(true).format('MMMM D YYYY, HH:mm'))

                $("#booking-first_customer_note").val(placeholder_first_reminder)
                $("#booking-notification_confirmed_text").val(placeholder_first_reminder)
            }

            // service autocomplete
            service_id = $(this).val()
            $("#booking-service_name").val("")

            jQuery('#booking-service_name').remove();
            jQuery('.booking-service_name_content').append("<input class='form-control' id='booking-service_name' type='text' placeholder='Service name'>");

            $("#booking-service_name").autocomplete({
                valueKey:'title',
                minLength: 1,
                visibleLimit: 10,
                source:[{
                    url:"/services/autocomplete?s=%QUERY%&service_id=" + service_id,
                    type:'remote',
                    getValue:function(item){
                        return item.title
                    },
                    ajax:{
                        dataType : 'json'
                    }
                }
            ]}).on('selected.xdsoft',function(e,datum){
                document.getElementById("booking-service_name").addEventListener("blur", function(){
        
                    if ($("#booking-first_customer_note").attr("disabled") != "disabled") {
                        var placeholder_first_reminder = "Your booking for '<service>' has been confirmed for '<date>'"
                        placeholder_first_reminder = placeholder_first_reminder.replace('<service>', $("[id='booking-service'] :selected").text() + " " + $("#booking-service_name").val())
                        placeholder_first_reminder = placeholder_first_reminder.replace('<date>', moment($("#booking-date").val(), 'YYYY-MM-DD HH:mm').utc(true).format('MMMM D YYYY, HH:mm'))
        
                        $("#booking-first_customer_note").val(placeholder_first_reminder)
                        $("#booking-notification_confirmed_text").val(placeholder_first_reminder)
                    }
                });
        
            });

            $("#booking-service_name").on("blur", function(){
                document.getElementById("booking-service_name").addEventListener("blur", function(){
        
                    if ($("#booking-first_customer_note").attr("disabled") != "disabled") {
                        var placeholder_first_reminder = "Your booking for '<service>' has been confirmed for '<date>'"
                        placeholder_first_reminder = placeholder_first_reminder.replace('<service>', $("[id='booking-service'] :selected").text() + " " + $("#booking-service_name").val())
                        placeholder_first_reminder = placeholder_first_reminder.replace('<date>', moment($("#booking-date").val(), 'YYYY-MM-DD HH:mm').utc(true).format('MMMM D YYYY, HH:mm'))
        
                        $("#booking-first_customer_note").val(placeholder_first_reminder)
                        $("#booking-notification_confirmed_text").val(placeholder_first_reminder)
                    }
                });
            })

            // var options = { 
            //     serviceUrl: '/services/autocomplete',
            //     params: {
            //         service_id: service_id
            //     },
            //     onSelect: function(value, data) { 
            //         console.log(data)
            //         $("#booking-service_name").val(data);
            //     }
            // }
            // $('#booking-service_name').autocomplete(options)
        })
        
        $("#booking-date").on("blur", function(){
           
            if ($("#booking-first_customer_note").attr("disabled") != "disabled") {
                var placeholder_first_reminder = "Your booking for '<service>' has been confirmed for '<date>'"
                placeholder_first_reminder = placeholder_first_reminder.replace('<service>', $("[id='booking-service'] :selected").text() + " " + $("#booking-service_name").val())
                placeholder_first_reminder = placeholder_first_reminder.replace('<date>', moment($("#booking-date").val(), 'YYYY-MM-DD HH:mm').utc(true).format('MMMM D YYYY, HH:mm'))

                $("#booking-first_customer_note").val(placeholder_first_reminder)
                $("#booking-notification_confirmed_text").val(placeholder_first_reminder)
            }
        })

        Get()
    }
    
    function Get(){

        //get service details
        if (service_name_id != null){
            $.ajax({
                url: `/services/get_detail/${service_name_id}`,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    $("#service-name").val(data.service.id)
                    $("#service-detail_name").val(data.name)
                    $("#service-info").val(data.info)
                    $("#service-address").val(data.address)
                    $("#service-phone").val(data.phone)
                    $("#service-website").val(data.website)
                }
            })
        }
        else {

            $.ajax({
                url: `/services/get`,
                type: 'GET',
                dataType: "json",
                data:{
                    service_id: service_id
                },
                success: function (data) {
                   
                    table_services.rows().clear().draw();
                    $("#booking-service_detail").html("")
                    $("#booking-service_detail").append("<option value=''>-</option>")
                    
                    if (data.length == 0) {
                        $("#table-services .dataTables_empty").html("No services found!")
                        return;
                    }
                    
                    jQuery.each(data, function (i, val) {
                        let photo = val.service.photo.url != null ? `<img src='${val.service.photo.url}' alt='photo' width='100'>` : "<img src='/assets/default_menu.png' width='100' alt='photo'>"
                        let newRow = table_services.row.add( [
                            val.id,
                            val.service.name,
                            photo,
                            val.name,
                            `<a href='javascript:void(0)' type='button' data-id_service='${val.id}' data-toggle="modal" data-target="#modal-service" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a>
                            <a href='javascript:void(0)' class='btn_delete_service_${val.id}' data-id_service='${data.id}'><i class='icon-delete fas fa-trash-alt'></i></a>`
                        ] ).draw( false ).node();
    
                        // add id to row 
                        $(newRow).attr("id", "service-" + val.id);
                        
                        $(`.btn_delete_service_${val.id}`).on("click", function(){
                            Swal.fire({
                                title: "Would you really want to delete this service ?",
                                icon: "warning",
                                text: ``,
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Yes',
                            }).then(result => {
                                if (result.isConfirmed) {
                                    DeleteServiceName(val.id)
                                }
                            });
                        })
                        
                    });
                }
            })
        }
    }

    function CreateService(){
        $("#btn-save-new_service").html("<img class='loader' src='/assets/loader_3.svg'>")
        $("#btn-save-new_service").attr("disabled", true)
        $(".btn_close_modal").attr("disabled", true)
        $("#service-new_name").attr("disabled", true)

        var formData = new FormData();
        formData.append('name', $("#service-new_name").val());
        if ($("#service-photo")[0].files[0] != undefined) formData.append('photo', $("#service-photo")[0].files[0]);

        $.ajax({
            url: `/services`,
            type: 'POST',
            data: formData,
            dataType: "json",
            processData: false,
            contentType: false,
            success: function (data) {
             
                $("#service-name").prepend(`<option value='${data.id}'>${data.name}</option>`)
                $("#service-name").val(data.id)

                Reset()

                GetAllServices()

                $("#modal-new_service .close").click();
            }
        });
    }

    function CreateServiceName(){
        $("#btn-save-service").html("<img class='loader' src='/assets/loader_3.svg'>")
        $("#btn-save-service").attr("disabled", true)
        $(".btn_close_modal").attr("disabled", true)
        $("#service-detail_name").attr("disabled", true)
        $("#service-info").attr("disabled", true)
        $("#service-address").attr("disabled", true)
        $("#service-phone").attr("disabled", true)
        $("#service-website").attr("disabled", true)

        var formData = new FormData();
        formData.append('name', $("#service-detail_name").val());
        formData.append('service_id', $("#service-name").val());
        formData.append('info', $("#service-info").val());
        formData.append('address', $("#service-address").val());
        formData.append('phone', $("#service-phone").val());
        formData.append('website', $("#service-website").val());

        $.ajax({
            url: `/services/create_service_detail`,
            type: 'POST',
            data: formData,
            dataType: "json",
            processData: false,
            contentType: false,
            success: function (data) {
                let photo = data.service.photo.url != null ? `<img src='${data.service.photo.url}' alt='photo' width='100'>` : "<img src='/assets/default_menu.png' width='100' alt='photo'>"
                let newRow = table_services.row.add( [
                    data.id,
                    photo,
                    data.service.name,
                    data.name,
                    `<a href='javascript:void(0)' type='button' data-id_service='${data.id}' data-toggle="modal" data-target="#modal-service" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-edit'></i></a>
                    <a href='javascript:void(0)' class='btn_delete_service_${data.id}' data-id_service='${data.id}'><i class='icon-delete fas fa-trash-alt'></i></a>`
                ] ).draw(false).node();

                $(newRow).attr("id", "service-" + data.id);

                Reset()

                $("#modal-service .close").click();
            }
        });
    }
    
    function UpdateService(){

        var formData = new FormData();
        formData.append('id', id_serv);
        formData.append('name', $("#service-new_name").val());
        if ($("#service-photo")[0].files[0] != undefined) formData.append('photo', $("#service-photo")[0].files[0]);

        $.ajax({
            url: `/services/update_service`,
            type: 'PUT',
            data: formData,
            dataType: "json",
            processData: false,
            contentType: false,
            success: function (data) {
                $("#services option:selected").text($("#service-new_name").val());

                Reset();

                $("#modal-new_service .close").click();
            }
        });
    }

    function UpdateServiceName(){
        $.ajax({
            url: `/services/update_service_name`,
            type: 'PUT',
            data: {
                service_name_id: service_id,
                service_name: $("#service-detail_name").val(),
                info: $("#service-info").val(),
                address: $("#service-address").val(),
                phone: $("#service-phone").val(),
                website: $("#service-website").val()
            },
            dataType: "json",
            success: function (data) {
                $("#service-" + service_id + " td:nth-child(3)").text($("#service-detail_name").val());

                Reset();

                $("#modal-service .close").click();
            }
        });
    }

    function GetAllServices() {
        // get all services 

        if ($("#services").length > 0)
            $("#services").html("")
        if ($("#booking-service").length > 0)
            $("#booking-service").html("")

        $.ajax({
            url: `/services/get_all_services`,
            type: 'GET',
            dataType: "json",
            success: function (data) {
                $("#booking-service").append("<option value=''>-</option>")
                jQuery.each(data, function (i, val) {
                    if ($("#services").length > 0)
                        $("#services").append(`<option value='${val.id}'>${val.name}</option>`)
                    if ($("#booking-service").length > 0) {
                        $("#booking-service").append(`<option value='${val.id}'>${val.name}</option>`)
                    }
                    
                });
            }
        })
    }

    function DeleteService(id) {
        $.ajax({
            url: `/services/delete_service/${id}`,
            type: 'DELETE',
            dataType: "json",
            success: function (data) {
                $("#services option:selected").remove();

                $("#services").next().remove()
                service_id = null;
                Get()
            }
        })
    }

    function DeleteServiceName(id){
        $.ajax({
            url: `/services/delete_service_name/${id}`,
            type: 'DELETE',
            dataType: "json",
            success: function (data) {
                table_services.row($("#service-" + id)).remove().draw();
            }
        })
    }

    function GetServiceDetails(selected_service_detail_id) {

        $("#booking-service_detail").html("")
        $("#booking-service_detail").append("<option value=''>-</option>")

        $.ajax({
            url: `/services/get_details/${$('#booking-service').val()}`,
            type: 'GET',
            dataType: "json",
            success: function (data) {
                jQuery.each(data, function (i, val) {
                    $("#booking-service_detail").append(`<option value='${val.id}'>${val.name}</option>`)
                });

                $("#booking-service_detail").val("")
            }
        })
    }

    Init();
    

    function Reset() {
        
        $("#btn-save-service").html("Save")
        $("#btn-save-service").attr("disabled", false)
        $("#btn-save-new_service").html("Save")
        $("#btn-save-new_service").attr("disabled", false)
        $(".btn_close_modal").attr("disabled", false)
        $("#service-new_name").attr("disabled", false)
        $("#service-detail_name").attr("disabled", false)
        $("#service-new_name").val("")
        $("#service-detail_name").val("")
        $("#service-thumbnail").addClass("visually-hidden")
        $("#service-info").attr("disabled", false)
        $("#service-address").attr("disabled", false)
        $("#service-phone").attr("disabled", false)
        $("#service-website").attr("disabled", false)
        $("#service-info").val("")
        $("#service-address").val("")
        $("#service-phone").val("")
        $("#service-detail_name").val("")
        $("#service-website").val("")

        service_name_id = "";
        service_id = "";
        id_serv = null;
    }
});