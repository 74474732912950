const { get } = require("jquery");

document.addEventListener("turbolinks:load", function() {
    if(window.location.pathname == "/members") {
        let method;
        let requiredFields = [];
        let member_id;
        let table_members;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_members != null)
                table_members.destroy();
        })

        table_members = $('#table-members').DataTable({
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bOrder": true,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            'order' : [[4,'desc']]
        });

        var modalMember = document.getElementById('modal-member')

        modalMember.addEventListener('show.bs.modal', function (e) {
            method = $(e.relatedTarget).data('method')

            if (method == "new") {
                
            }
            else if (method == "edit") {
                member_id = $(e.relatedTarget).data('member_id')

                get()
            }
        })

        modalMember.addEventListener('hidden.bs.modal', function (e) {
            resetFields()
        });

        // ***** FUNCTIONS *****

        function get() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            let url = "/members/get"
            if (member_id != null) url = "/members/get/" + member_id

            $.ajax({
                url: url,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    if (member_id != null) {
                        $("#member-membership_number").val(data.membership_number)
                        $("#member-fullname").val(data.fullname)
                        $("#member-avatar").attr("src", data.photo)
                        $("#member-avatar").removeClass("visually-hidden")
                        $("#member-email").val(data.email)
                        $("#member-phone").val(data.phone)
                        $("#member-registered").text(moment(data.registered).locale("en").format('DD/MM/YYYY HH:mm',"en"))
                    }
                    else {
                        table_members.rows().clear().draw();
                        jQuery.each(data, function (i, val) {
                            let newRow = table_members.row.add( [
                                val.id, 
                                val.membership_number,
                                val.fullname,
                                val.email, 
                                val.phone,
                                //val.created_at,
                                `<a href='javascript:void(0)' title='See' type='button' data-member_id='${val.id}' data-slug='${val.slug}' data-toggle="modal" data-target="#modal-member" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-eye'></i></a>`
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "member-" + val.id);
        
                            $('.btn_delete_member_' + val.id).on("click", function(e){
                                destroy(val.id, val.title)
                            });
                        });
                    }

                    JsLoadingOverlay.hide();
                }
            });
        }

        function resetFields() {
            $("#member-membership_number").val("")
            $("#member-fullname").val("")
            $("#member-avatar").attr("src", "")
            $("#member-avatar").addClass("visually-hidden")
            $("#member-email").val("")
            $("#member-phone").val("")
            $("#member-registered").text("")

            member_id = null
        }

        get()
    }
});