const { get } = require("jquery");

document.addEventListener("turbolinks:load", function() {

    // login remember me
    $("#new_admin_user").submit(function(e){
        e.preventDefault();
        
        if ($('#admin_user_remember_me').prop('checked')) {

            var username = $('#admin_user_email').val();
            var password = $('#admin_user_password').val();
            // set cookies to expire in 14 days
            //Cookies.set('username', username, { expires: 14 });
            //Cookies.set('password', password, { expires: 14 });
            //Cookies.set('remember', true, { expires: 14 });

            
        } else {
            // reset cookies
            // Cookies.set('username', null);
            // Cookies.set('password', null);
            // Cookies.set('remember', null);
        }

        e.currentTarget.submit();
    })

    // var remember =  Cookies.get('remember');
  
    // $('#admin_user_remember_me').attr('checked', remember)
    // if ( remember == 'true' ) {
    //     var username = Cookies.get('username');
    //     var password = Cookies.get('password');
    //     // autofill the fields
    //     $('#admin_user_email').attr("value", username);
    //     $('#admin_user_password').attr("value", password);
    // }
    // ****************************

    var table_users;
    var user_id = null;
    var method = '';

    if (document.getElementById('modal-user')){
        var modalUser = document.getElementById('modal-user')
        modalUser.addEventListener('show.bs.modal', function (e) {
            user_id = $(e.relatedTarget).data("id_user")
            
            method = $(e.relatedTarget).data('method')

            Get()
        })

        modalUser.addEventListener('shown.bs.modal', function (e) {
            $("#user-fullname").focus()
        });

        modalUser.addEventListener('hidden.bs.modal', function (e) {
            Reset()
        });
    }


    $("#btn-save-user").on("click", function(){
        
        $(this).html("<img class='loader' src='/assets/loader.svg'>")
        $(this).attr("disabled", true)
        $("#user-fullname").attr('disabled', true)
        $("#user-email").attr('disabled', true)
        $("#user-password").attr('disabled', true)
        $("#user-password_confirmation").attr('disabled', true)

        if (method == 'new') {
           Create()
        }
        else if (method == 'edit' || $(this).data("method") == "edit") {

            var formData = new FormData();
            formData.append('fullname', $("#user-fullname").val().trim());

            if ($("#user-password").val() != "" && $("#user-password_confirmation").val() != "") {
                formData.append('password', $("#user-password").val().trim());
            }

            $.ajax({
                url: `/admin_users/${$('#user-id').val()}`,
                type: 'PUT',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    if (data.state == 'success'){
                        $(".current_admin_username").text($("#user-fullname").val())

                        Swal.fire(
                            'Profile Updated!',
                            '',
                            'success'
                        )
                    }

                    $("#user-fullname").attr('disabled', false)
                    $("#user-password").attr('disabled', false)
                    $("#user-password_confirmation").attr('disabled', false)
                    $("#btn-save-user").html("Save")
                    $("#btn-save-user").attr("disabled", false)
                }
            })
        }
    })

    function Init() {
        table_users = $('#table-users').DataTable({
            "columnDefs": [
                {"className": "comment-fluid", "targets": 1}
            ],
            "pageLength": 100,
            "bPaginate": true,
            "bFilter": false,
            "bInfo": false,
            "bOrder": true,
            "bSort": true,
            "ordering": true,
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            buttons: [
                'csv', 'excel', 'pdf'
            ]
        });

        Get()
    }

    function Get(){
        if (user_id != null){
            $.ajax({
                url: `/admin_users/get`,
                type: 'GET',
                data: {
                    id: user_id,
                },
                dataType: "json",
                success: function (data) {

                    $("#user-fullname").val(data.fullname);
                    $("#user-email").val(data.email);
                }
            })
        }
        else {
            $("#table-users .dataTables_empty").html("<img src='/assets/loader_2.svg' width='64'>")

            $.ajax({
                url: `/admin_users/get`,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    table_users.rows().clear().draw();
                    
                    jQuery.each(data, function (i, val) {
                        var del = ' '
                        if ($("#user_id").val() != val.id && val.role != 0){
                            del = `<a href='javascript:void(0)' class='btn_delete_user_${val.id}' data-id_user='${val.id}'><i class='icon-delete fas fa-trash-alt'></i></a>`
                        }

                        let newRow = table_users.row.add( [
                            val.id,
                            val.fullname,
                            val.email,
                            moment(val.created_at).locale("en").format('DD/MM/YYYY HH:mm',"en"),
                            `<a href='javascript:void(0)' type='button' data-id_user='${val.id}' data-toggle="modal" data-target="#modal-user" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='fas fa-eye'></i></a> ` + 
                            del
                        ] ).draw( false ).node();
    
                        // add id to row 
                        $(newRow).attr("id", "user-" + val.id);
                        
                        $('.btn_delete_user_' + val.id).click(function(e){
                            Swal.fire({
                                title: "Would you really want to delete this admin?",
                                icon: "warning",
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Delete',
                            }).then(result => {
                                if (result.isConfirmed) {
                                    Delete(val.id)
                                    table_users.row($("#user-" + val.id)).remove().draw()
                                }
                            });
                        });
                    });
                }
            })
        }
    }
    
    function Create() {
        var formData = new FormData();
        formData.append('fullname', $("#user-fullname").val().trim());
        formData.append('email', $("#user-email").val());
        formData.append('password', $("#user-password").val());

        $.ajax({
            url: `/admin_users/admin_users_new`,
            type: 'POST',
            data: formData,
            dataType: "json",
            processData: false,
            contentType: false,
            success: function (data) {
                
                let newRow = table_users.row.add( [
                    data.id,
                    data.fullname,
                    data.email,
                    moment(data.created_at).locale("en").format('DD/MM/YYYY HH:mm',"en"),
                    `<a href='javascript:void(0)' type='button' data-id_user='${data.id}' data-toggle="modal" data-target="#modal-user" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='fas fa-eye'></i></a>
                    <a href='javascript:void(0)' class='btn_delete_user_${data.id}' data-id_user='${data.id}'><i class='icon-delete fas fa-trash-alt'></i></a>`
                ] ).draw( false ).node();

                // add id to row 
                $(newRow).attr("id", "user-" + data.id);
                
                $('.btn_delete_user_' + data.id).click(function(e){
                    Swal.fire({
                        title: "Would you really want to delete this admin?",
                        icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Delete',
                    }).then(result => {
                        if (result.isConfirmed) {
                            Delete(data.id)
                            table_users.row($("#user-" + data.id)).remove().draw()
                        }
                    });
                });

                $("#modal-user .close").click();

                Reset()
            }
        })
    }

    function Delete(user_id) {
        $.ajax({
            url: `/admin_users/${user_id}`,
            type: 'DELETE',
            dataType: "json",
            success: function (data) {
    
            }
        })
    }

    function Reset() {
        $("#btn-save-user").attr("disabled", false)
        $(".btn_close_modal").attr("disabled", false)
        $("#user-fullname").attr('disabled', false)
        $("#user-email").attr('disabled', false)
        $("#user-password").attr('disabled', false)
        $("#user-password_confirmation").attr('disabled', false)
        $("#btn-save-user").val("Save")
        $("#user-fullname").val("");
        $("#user-email").val("");
        $("#user-password").val("");
        $("#user-password_confirmation").val("");
    }

    Init()
})
