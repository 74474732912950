document.addEventListener("turbolinks:load", function() {
    if(window.location.pathname == "/home_menus") {
        let menu_id;
        let table_home_menus;
        let requiredFields = [];

        document.addEventListener("turbolinks:before-cache", function() {
            
        })

        table_home_menus = $('#table-home_menus').DataTable({
            "pageLength": 100,
            "bPaginate": false,
            "bFilter": false,
            "bInfo": false,
            "bOrder": false,
            "bSort": false,
            "ordering": false,
            "searching": false,
            "bAutoWidth": false
        });

        var modalHomeMenu = document.getElementById('modal-home_menu')

        modalHomeMenu.addEventListener('show.bs.modal', function (e) {
            method = $(e.relatedTarget).data('method')

            menu_id = $(e.relatedTarget).data('menu_id')

            get()
        })

        modalHomeMenu.addEventListener('hidden.bs.modal', function (e) {
            resetFields()
        });

        $("#btn-save-home_menu").on("click", function() {
            if (!$("#home_menu-name").val()) requiredFields.push("#home_menu-name")

            if (requiredFields.length > 0) {
                requiredFields.forEach(function(field, i,) {
                    $(field).addClass("is-invalid")
                });

                requiredFields = []
                
                return false
            }

            update()
        })

        // ***** FUNCTIONS *****

        function get() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });

            let url = "/home_menus/get"
            if (menu_id != null) url = "/home_menus/get/" + menu_id

            $.ajax({
                url: url,
                type: 'GET',
                dataType: "json",
                success: function (data) {
                    if (menu_id != null) {
                        $("#home_menu-name").val(data.name)
                        $("#home_menu-order").val(data.order)
                        $("#home_menu-slug").val(data.slug)
                        if (data.photo.url != null) {
                            $("#home_menu-thumbnail").attr("src", data.photo.url)
                        } else {
                            $("#home_menu-thumbnail").attr("src", '/assets/default_menu.png')
                        }
                    }
                    else {
                        table_home_menus.rows().clear().draw();
                        jQuery.each(data, function (i, val) {
                            let photo = val.photo.url != null ? `<img src='${val.photo.url}' alt='photo' width='100'>` : "<img src='/assets/default_menu.png' width='100' alt='photo'>"
                            let newRow = table_home_menus.row.add( [
                                val.name,
                                val.order,
                                val.slug,
                                photo,
                                `<a href='javascript:void(0)' title='See' type='button' data-menu_id='${val.id}' data-toggle="modal" data-target="#modal-home_menu" data-method='edit' data-backdrop="static" data-keyboard="false"><i class='icon-edit fas fa-eye'></i></a>`
                            ] ).draw( false ).node();
        
                            $(newRow).attr("id", "menu-" + val.id);
        
                        });
                    }

                    JsLoadingOverlay.hide();
                }
            });
        }

        function update() {
            JsLoadingOverlay.show({
                'spinnerIcon': 'ball-pulse-sync'
            });
            
            var formData = new FormData();
            formData.append('name', $("#home_menu-name").val().trim());
            formData.append('order', $("#home_menu-order").val().trim());
            formData.append('slug', $("#home_menu-slug").val().trim());
            if ($("#home_menu-photo")[0].files[0] != undefined) formData.append('photo', $("#home_menu-photo")[0].files[0]);

            $.ajax({
                url: `/home_menus/${menu_id}`,
                type: 'PUT',
                data: formData,
                dataType: "json",
                processData: false,
                contentType: false,
                success: function (data) {
                    photo = `<img src='${data.photo.url}' class='photo' width='100px' alt='image'>`
                    $("#menu-" + menu_id + " td:nth-child(1)").html(data.name)
                    $("#menu-" + menu_id + " td:nth-child(2)").html(data.order)
                    $("#menu-" + menu_id + " td:nth-child(3)").html(data.slug)
                    $("#menu-" + menu_id + " td:nth-child(4)").html(photo)

                    $("#modal-home_menu .close").click();
                    
                    JsLoadingOverlay.hide();
                }
            });
        }

        function resetFields() {
            $("#home_menu-name").val("")
            $("#home_menu-photo").attr("src", "")

            member_id = null
        }

        get()
    }
});